$breakPoints : (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
) !default;

$gridColumns: 12 !default;
$gridRowGap: 18px !default;
$gridColumnGap: 18px !default;
$columnPrefix: '.col' !default;
$alignPrefix: '.align' !default;

$alignments: start, end, center, stretch;

$horizontal-alignment : (
        left: start,
        right: end,
        h-center: center,
        h-stretch: stretch
);

$vertical-alignment : (
        top: start,
        bottom: end,
        v-center: center,
        v-stretch: stretch
);